<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Performance Improvement
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <div class="m-32" v-if="loadingData">
      <Loader size="xxs" />
    </div>
    <div v-else>
      <template v-if="lineManager">
        <div class="w-full p-5 flex mt-1 flex-col">
          <Tabs
            class="mt-6 mx-3"
            :tabs="tabsItems"
            :active-tab="activeTab"
            @currentTab="activeTab = $event"
            :border="true"
          />
          <div style="height:100%" v-if="loadingData">
            <loader size="xxs" />
          </div>
          <div v-else class="w-full flex flex-col">
            <TPerformanceImprovementPlans v-if="activeTab === 'Performance Improvement Plans'" />
            <TPIPPerformanceReview v-if="activeTab === 'PIP Performance Review'" />
            <TPIPCloseoutSession v-if="activeTab === 'PIP Closeout Session'" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import Loader from '@scelloo/cloudenly-ui/src/components/loader';
  import Tabs from '@scelloo/cloudenly-ui/src/components/tab';
  import TPerformanceImprovementPlans from "./Tabs/TPerformanceImprovementPlans";
  import TPIPPerformanceReview from "./Tabs/TPIPPerformanceReview";
  import TPIPCloseoutSession from "./Tabs/TPIPCloseoutSession";

  export default {
    name: "ESSPerformanceImprovement",
    components: {
      Breadcrumb,
      Tabs,
      Loader,
      TPerformanceImprovementPlans,
      TPIPPerformanceReview,
      TPIPCloseoutSession
    },
    data() {
      return {
        perfArray: [],
        openModal: false,
        kpaSummary: {},
        loadingData: false,
        lineManager: false,
        tabsItems: [
          "Performance Improvement Plans",
          "PIP Performance Review",
          "PIP Closeout Session",
        ],
        activeTab: this.$route.query.activeTab || "Performance Improvement Plans",
        breadcrumbs: [
          {
            disabled: false,
            text: "Performance",
            href: "Performance",
            id: "Performance"
          },
          {
            disabled: false,
            text: "Performance Improvement",
            href: "performance-improvement",
            id: "PerformanceImprovement"
          }
        ]
      };
  },

  methods: {
    checkIfManager() {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((response) => {
        if (response.data.directReport.length > 0) {
          this.lineManager = true;
        }
        else {
          this.lineManager = false;
        }
      })
    }
  },

  mounted() {
    this.checkIfManager();
  },

  };
</script>